<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <template v-if="!loadingLandingPage">
      <main role="main" class="flex-shrink-0 mb-5">
        <div class="header-row">
          <div class="container">
            <div class="row">
              <div class="col">
                <div class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 shadow-sm">
                  <div class="my-0 mr-md-auto font-weight-normal">
                    <img v-if="landingpage.logo" :src="landingpage.logo" style="max-height: 50px;" />
                    <span v-else>{{landingpage.agency_name}}</span>
                  </div>
                  <div>{{ landingpage.upper_right }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col my-4">
              <div v-html="landingpage.content_above"></div>
              <reply-engine-generator v-if="landingpage.agency_id"
                                     :agency-id="landingpage.agency_id"
                                     :header-text="landingpage.settings.embed_form_settings.title"
                                     :background-color="landingpage.settings.embed_form_settings.background_color"
                                     :main-text-color="landingpage.settings.embed_form_settings.main_text_color"
                                     :primary-button-background-color="landingpage.settings.embed_form_settings.primary_button_background_color"
                                     :primary-button-text-color="landingpage.settings.embed_form_settings.primary_button_text_color"
                                     :secondary-button-background-color="landingpage.settings.embed_form_settings.secondary_button_background_color"
                                     :secondary-button-text-color="landingpage.settings.embed_form_settings.secondary_button_text_color"
                                     :copy-area-background-color="landingpage.settings.embed_form_settings.copy_area_background_color"
                                     :copy-area-text-color="landingpage.settings.embed_form_settings.copy_area_text_color"
                                     :copy-button-background-color="landingpage.settings.embed_form_settings.copy_button_background_color"
                                     :copy-button-text-color="landingpage.settings.embed_form_settings.copy_button_text_color"
                                     :default-map-query="landingpage.agency_name"
                                     :qr-text="landingpage.settings.embed_form_settings.qr_text"
                                     :google-api-key="landingpage.admin_settings.google_api_key"
                                      return-type="replies"

              ></reply-engine-generator>
              <div v-html="landingpage.content_below"></div>
            </div>
          </div>
        </div>
      </main>
      <footer class="footer p-0 mt-auto mt-5">
        <div class="footer-row">
          <div class="container">
            <div class="row">
              <div class="col">
                <div class="d-flex flex-column flex-md-row align-items-center px-md-4 shadow-sm">
                  <div class="my-0 mr-md-auto font-weight-normal">
                    <img v-if="landingpage.logo" :src="landingpage.logo" style="max-height: 30px;" />
                    <span v-else>{{landingpage.agency_name}}</span>
                  </div>
                  <div>{{ landingpage.upper_right }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-footer py-4">
          <div class="container">
            <div class="row">
              <div class="col text-center">
                <span>Copyright {{new Date().getFullYear()}} {{ landingpage.agency_name }}</span>
                |
                <a @click="showPrivacyModal = true" >Privacy Policy</a>
                |
                <a @click="showTermsModal = true">Terms of Service</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </template>
    <div v-else class="text-center mt-4">
      <b-spinner></b-spinner>
    </div>
    <b-modal v-model="showPrivacyModal" title="Privacy Policy" :ok-only="true" size="lg" ok-variant="dark">
      <div v-if="landingpage.settings" v-html="landingpage.settings.privacy_policy"></div>
    </b-modal>
    <b-modal v-model="showTermsModal" title="Terms of Service" :ok-only="true" size="lg" ok-variant="dark">
      <div v-if="landingpage.settings" v-html="landingpage.settings.terms_of_service"></div>
    </b-modal>
  </div>
</template>

<script>
import EmbedForm from '../../../../embed-form/src/components/Form';
export default {
  components:{
    'reply-engine-generator':EmbedForm
  },
  data() {
    return {
      loadingLandingPage: false,
      showPrivacyModal:false,
      showTermsModal:false,
      landingpage:{
        admin_settings:''
      }
    }
  },
  metaInfo () {
    return {
      title: this.landingpage.agency_name+' Respond Engine',
      meta: [
        { name: 'og:title', content: this.landingpage.agency_name+' Review Responder' },
        { name: 'description', content: '' },
        { name: 'og:description', content: '' },
        { name: 'og:image', content: '' }
      ],
      link: [
        { hid: 'favorite-icon', rel: 'icon', href: this.landingpage.favicon, type: 'image/x-icon' }
      ],
    }
  },
  computed: {

  },

  mounted() {
    this.getLandingPage();
  },

  methods: {
    getLandingPage() {
      this.loadingLandingPage = true;
      this.$store
          .dispatch('landingPage/find', this.$route.params.id)
          .then((res) => {
            if(res === null){
              location.href = "https://u.reviewour.biz/linknotfound";
            }
            this.loadingLandingPage = false
            this.landingpage = res;
          })
          .catch(() => {
            this.loadingLandingPage = false
          })
    },
  },
}
</script>
<style>
@import "https://fonts.googleapis.com/css2?family=Raleway&display=swap";
</style>
<style lang="scss" scoped>

.header-row{
  background:#e7ebf3;
  line-height: 40px;
  font-weight: bold;
}
.footer-row{
  background: #e7ebf3;
  color: #333;
  font-weight: bold;
  padding: 10px 0;
}
main{
  /*font-size: 1.5rem;*/
  background-color: #fff;
}
footer{
  left: 0;
  font-size: 1.25em;
  color: #edeef7;
  .bottom-footer{
    background: #474747;
  }
  a{
    color: inherit;
    cursor: pointer;
  }
}
</style>
